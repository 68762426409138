<template>
  <NeoPageLayout>
    <div class="supplierDetail _ptb-30 _plr-20" v-if="refresh">
      <header>
        <NeoBigTitle v-show="!edit" :text="supplierName" />
        <a-radio-group v-model="tab">
          <a-radio-button value="baseInfo">{{ $t('customerConfig.supplier_baseInfo') }}</a-radio-button>
          <a-radio-button value="priceList">{{ $t('companyInfo.price_list') }}</a-radio-button>
        </a-radio-group>
      </header>

      <main class="_mt-20">
        <SupplierBaseInfoDetail
          v-show="tab1"
          ref="baseinfo"
          :show="tab1"
          @save="save"
          @editing="handleEdit"
          @getName="getSupplierName"
        />
        <PriceLists v-show="tab2" :show="tab2" />
      </main>
    </div>
  </NeoPageLayout>
</template>

<script>
import SupplierBaseInfoDetail from './components/SupplierBaseInfoDetail'
import PriceLists from './components/PriceLists'
export default {
  name: 'supplierDetail',
  components: {
    SupplierBaseInfoDetail,
    PriceLists,
  },
  data() {
    return {
      tab: 'baseInfo',
      tabs: ['baseInfo', 'priceList'],
      edit: false,
      supplierName: '',
      refresh: true,
    }
  },
  computed: {
    id() {
      return this.$route.query.id
    },
    tab1() {
      return this.tab === this.tabs[0]
    },
    tab2() {
      return this.tab === this.tabs[1]
    },
  },
  watch: {
    id() {
      this.tab = this.tabs[0]
      this.$refs.baseinfo.fetchAll()
      // this.refresh = false
      // this.$nextTick(() => {
      //   this.refresh = true
      // })
    },
  },
  methods: {
    save(edit) {
      this.edit = edit
    },
    handleEdit(edit) {
      this.edit = edit
    },
    getSupplierName(name) {
      console.log(`name`, name)
      this.supplierName = name
    },
  },
}
</script>

<style lang="less" scoped>
.supplierDetail {
}
</style>
